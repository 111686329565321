import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Navbar extends Component {
    render() {
        return (
            <div className='w-full flex flex-row items-center p-6 justify-between z-50 fixed'>
                <div className='flex flex-row space-x-6'>
                    <Link className='hover:opacity-50' to='/'>
                        <p className='text-white mobile:text-xl desktop:text-4xl font-semibold'>Rway Logistics</p>
                    </Link>
                    <a href='/#main' className='desktop:text-2xl text-white font-light hover:opacity-50'>Главная</a>
                    <a href='/#about' className='desktop:text-2xl text-white font-light hover:opacity-50'>О нас</a>
                    <a href='/#contacts' className='desktop:text-2xl text-white font-light hover:opacity-50'>Контакты</a>
                </div>
                <div className='space-x-6 items-center mobile:hidden desktop:flex'>
                    <div className='space-y-0.5'>
                        <p className='text-xl text-white font-light'>+7 771 266 0825</p>
                        <p className='text-xl text-white font-light'>+7 705 608 6247</p>
                    </div>
                    <Link to='/select-park' className='p-2 px-6 border-2 border-[#FDC200] rounded-xl hover:opacity-50'>
                        <p className='text-xl text-[#FDC200] font-semibold'>Пополнить</p>
                    </Link>
                </div>
            </div>
        )
    }
};

export default Navbar;
