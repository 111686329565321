import React, { Component } from 'react';
import Navbar from '../ui/Navbar';
import Greetings from '../ui/Greetings';
import WhyUs from '../ui/WhyUs';
import { IoIosArrowDown } from "react-icons/io";
import { Link } from 'react-router-dom';

class MainPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenAccordionOne: false,
            isOpenAccordionTwo: false,
            isOpenAccordionThree: false
        }
    }

    toggleAccordion1 = () => {
        this.setState({ isOpenAccordionOne: !this.state.isOpenAccordionOne, isOpenAccordionTwo: false, isOpenAccordionThree: false });
    };

    toggleAccordion2 = () => {
        this.setState({ isOpenAccordionTwo: !this.state.isOpenAccordionTwo, isOpenAccordionOne: false, isOpenAccordionThree: false });
    };

    toggleAccordion3 = () => {
        this.setState({ isOpenAccordionThree: !this.state.isOpenAccordionThree, isOpenAccordionTwo: false, isOpenAccordionOne: false });
    };

    render() {
        return (
            <div>
                <div>
                    <div id='main' className='w-full'>
                        <Navbar/>
                        <Greetings/>
                    </div>
                    <div className='w-full flex mobile:h-[250px] tablet:h-[400px] desktop:h-[500px] desktop2:h-[600px] bg-[#252521]'>
                        <svg className='absolute z-10' xmlns="http://www.w3.org/2000/svg" viewBox="0 40 1920 264" fill="none">
                            <path d="M0 31.1818H1920C1920 31.1818 1920 -61.6533 1920 73.0434C1920 207.74 1602 355.172 1440 192.478C1278 29.7837 1091.5 103.36 960 192.478C828.5 281.596 480 192.478 480 192.478C480 192.478 0 31.1818 0 192.478C0 353.774 0 31.1818 0 31.1818Z" fill="#807001"/>
                        </svg>
                        <div className='flex items-center absolute z-10 w-full justify-between flex-row mobile:px-6 tablet:px-16 desktop:px-64 mobile:mt-16 tablet:mt-28 desktop:mt-36 desktop2:mt-48'>
                            <div className='mobile:space-y-2 tablet:space-y-4 desktop:space-y-6'>
                                <p className='mobile:text-lg tablet:text-xl desktop:text-3xl desktop2:text-5xl text-white font-semibold'>Почему мы?</p>
                                <p className='mobile:w-32 mobile:text-xs tablet:w-64 desktop:w-96 tablet:text-lg desktop:text-2xl desktop2:text-3xl text-white font-light'>Мы - ваш надежный партнер в обеспечении удобства и безопасности при пополнении баланса. Гарантируем скорость, безопасность и поддержку 24/7 </p>
                            </div>
                            <img className='mobile:w-[200px] mobile:h-[150px] tablet:w-[300px] tablet:h-[250px] desktop:w-[500px] desktop:h-[400px] desktop2:w-[600px] desktop2:h-[500px]' src={require('../../img/icons.png')} alt="Background" />
                        </div>
                    </div>
                    <div id="about" className='w-full relative -z-10 mobile:h-[350px] tablet:h-[550px] desktop:h-[800px] bg-[#807001]'>
                        <svg className='absolute z-10' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 264" fill="none">
                            <path d="M0 172.55C0 311.589 357.501 88.4143 480 172.55C602.499 256.686 796 308.024 960 172.55C1124 37.077 1247.5 73.0843 1440 172.55C1632.5 272.016 1920 172.55 1920 172.55V-9.81374e-06H0C0 -9.81374e-06 0 33.5118 0 172.55Z" fill="#252521"/>
                        </svg>
                        <div className='flex items-center absolute z-10 w-full h-full flex-col justify-center mobile:space-y-2 desktop:space-y-6'>
                            <p className='mobile:text-xl tablet:text-4xl  desktop:text-5xl font-semibold text-white'>О нас</p>
                            <p className='mobile:text-md tablet:text-2xl desktop:text-3xl text-white text-center mobile:w-10/12 tablet:w-7/12 desktop:w-5/12 font-light'>Rway Logistics - в мире технологий и логистики. Мы предоставляем передовые IT-решения для оптимизации вашего бизнеса. Наша команда стремится к инновациям и высокому качеству обслуживания, помогая вам достичь успеха в вашей отрасли.</p>
                        </div>
                        <svg className='absolute z-10 bottom-0' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 254" fill="none">
                            <path d="M1920 83.4496C1920 -55.5889 1562.5 167.586 1440 83.4496C1317.5 -0.686438 1124 -52.0237 960 83.4496C796 218.923 672.5 182.916 480 83.4496C287.5 -16.0164 -1.52588e-05 83.4496 -1.52588e-05 83.4496V256H1920C1920 256 1920 222.488 1920 83.4496Z" fill="#252521"/>
                        </svg>
                    </div>
                    <div id='faq' className='w-full bg-[#252521] p-6 justify-center items-center flex flex-col'>
                        <p className='text-white mobile:text-xl tablet:text-2xl desktop:text-4xl'>Часто задаваемые вопросы</p>
                        <div className={ this.state.isOpenAccordionOne ? 'border-2 border-b-0 tablet:w-8/12 mobile:w-11/12 desktop:w-6/12 items-center justify-center flex flex-row rounded-t-2xl p-3 mt-6 space-x-6' : 'border-2 tablet:w-8/12 mobile:w-11/12 desktop:w-6/12 items-center justify-center flex flex-row rounded-full mt-6 p-3 space-x-6' }>
                            <p className='text-white mobile:text-xs tablet:text-xl desktop:text-3xl group-hover:opacity-50'>Как связаться с вашей службой поддержки?</p>
                            <button onClick={this.toggleAccordion1} className='hover:opacity-50'>
                                <IoIosArrowDown className='text-4xl text-white'/>
                            </button>
                        </div>
                        { this.state.isOpenAccordionOne && (
                            <div className='border-2 tablet:w-8/12 mobile:w-11/12 desktop:w-6/12 items-center justify-center flex flex-row rounded-b-2xl p-3 space-x-6'>
                                <p className='text-white mobile:text-xs tablet:text-xl desktop:text-2xl font-light group-hover:opacity-50'>Мы предлагаем несколько способов связи с нашей службой поддержки для решения любых вопросов или проблем. Вы можете связаться с нами по электронной почте support@example.com или же оставить заявку на сайте. Наша команда поддержки готова помочь вам в любое время дня и ночи для быстрого и качественного решения всех вопросов.</p>
                            </div>
                        ) }
                        <div className={ this.state.isOpenAccordionTwo ? 'border-2 border-b-0 tablet:w-8/12 mobile:w-11/12 desktop:w-6/12 items-center justify-center flex flex-row rounded-t-2xl p-3 mt-6 space-x-6' : 'border-2 tablet:w-8/12 mobile:w-11/12 desktop:w-6/12 items-center justify-center flex flex-row rounded-full mt-6 p-3 space-x-6' }>
                            <p className='text-white mobile:text-xs tablet:text-xl desktop:text-3xl group-hover:opacity-50'>Как долго обрабатывается платеж?</p>
                            <button onClick={this.toggleAccordion2} className='hover:opacity-50'>
                                <IoIosArrowDown className='text-4xl text-white'/>
                            </button>
                        </div>
                        { this.state.isOpenAccordionTwo && (
                            <div className='border-2 tablet:w-8/12 mobile:w-11/12 desktop:w-6/12 items-center justify-center flex flex-row rounded-b-2xl p-3 space-x-6'>
                                <p className='text-white mobile:text-xs tablet:text-xl desktop:text-2xl font-light group-hover:opacity-50'>Обработка платежей в нашей системе обычно занимает всего несколько минут. Однако, в зависимости от специфики операции и платежного метода, некоторые транзакции могут занимать до нескольких рабочих дней. Мы стремимся к максимальной оперативности и делаем все возможное для быстрой обработки всех платежей.</p>
                            </div>
                        ) }
                        <div className={ this.state.isOpenAccordionThree ? 'border-2 border-b-0 tablet:w-8/12 mobile:w-11/12 desktop:w-6/12 items-center justify-center flex flex-row rounded-t-2xl p-3 mt-6 space-x-6' : 'border-2 tablet:w-8/12 mobile:w-11/12 desktop:w-6/12 items-center justify-center flex flex-row rounded-full mt-6 p-3 space-x-6' }>
                            <p className='text-white mobile:text-xs tablet:text-xl desktop:text-3xl group-hover:opacity-50'>Какая политика возврата средств?</p>
                            <button onClick={this.toggleAccordion3} className='hover:opacity-50'>
                                <IoIosArrowDown className='text-4xl text-white'/>
                            </button>
                        </div>
                        { this.state.isOpenAccordionThree && (
                            <div className='border-2 tablet:w-8/12 mobile:w-11/12 desktop:w-6/12 items-center justify-center flex flex-row rounded-b-2xl p-3 space-x-6'>
                                <p className='text-white mobile:text-xs tablet:text-xl desktop:text-2xl font-light group-hover:opacity-50'>Мы предлагаем гибкую политику возврата средств. Если вам потребуется вернуть средства за наши услуги, пожалуйста, обратитесь к нашей службе поддержки. Мы гарантируем быстрое и прозрачное рассмотрение запроса на возврат.</p>
                            </div>
                        ) }
                    </div>
                    <div id='contacts' className='w-full bg-[#252521] p-6 justify-around items-center flex flex-row'>
                        <div className='tablet:flex mobile:hidden flex-col desktop:space-y-4 space-y-6'>
                            <p className='text-white tablet:text-2xl desktop:text-4xl font-semibold'>Возникли сложности?</p>
                            <p className='text-white font-light w-96 tablet:text-xl desktop:text-3xl'>Наша техническая поддержка работает 24/7 и дорожит каждой заявкой. Мы постараемся ответить вам максимально быстро</p>
                            <p className='text-white tablet:text-2xl desktop:text-4xl font-semibold'>Не хотите ждать?<br/>Напишите нам напрямую:</p>
                            <div>
                                <p className='text-white font-light w-96 tablet:text-xl desktop:text-3xl'>+7 771 266 0825</p>
                                <p className='text-white font-light w-96 mt-2 tablet:text-xl desktop:text-3xl'>Rwl.roman@yandex.kz</p>
                                <p className='text-white font-light mt-2 tablet:text-xl desktop:text-3xl'>Казахстан, Алматы, улица Исаева, дом 159</p>
                            </div>
                        </div>
                        <div className='flex flex-col mobile:space-y-4 tablet:space-y-2 desktop:space-y-6'>
                            <div className='border-b tablet:w-52 desktop:w-96 p-4'>
                                <input className='text-white/50 tablet:text-xl tablet:w-48 desktop:w-56  desktop:text-3xl bg-transparent outline-none' placeholder='Введите имя'/>
                            </div>
                            <div className='border-b tablet:w-52 desktop:w-96 p-4'>
                                <input className='text-white/50 tablet:text-xl tablet:w-48 desktop:w-56  desktop:text-3xl bg-transparent outline-none' placeholder='Введите email'/>
                            </div>
                            <div className='border-b tablet:w-52 desktop:w-96 p-4'>
                                <input className='text-white/50 tablet:text-xl tablet:w-48 desktop:w-56  desktop:text-3xl bg-transparent outline-none' placeholder='Введите номер'/>
                            </div>
                            <div className='border-b tablet:w-52 desktop:w-96 p-4'>
                                <input className='text-white/50 tablet:text-xl desktop:text-3xl tablet:w-48 desktop:w-80 bg-transparent outline-none' placeholder='Опишите проблему'/>
                            </div>
                            <button className='border-2 rounded-2xl p-2 justify-center items-center flex border-[#FDC200] group hover:bg-[#FDC200]'>
                                <p className='text-[#FDC200] tablet:text-xl desktop:text-3xl group-hover:text-white'>Отправить</p>
                            </button>
                        </div>  
                    </div>
                    <div className='w-full bg-[#252521] p-6 justify-between items-center flex flex-row'>
                        <p className='text-white font-bold mobile:text-lg tablet:text-2xl desktop:text-3xl'>Rway Logistics</p>
                        <div className='flex flex-col items-end'>
                            <p className='text-white font-semibold tablet:text-xl mobile:text-xs desktop:text-2xl text-right'>+7 771 266 0825</p>
                            <p className='text-white font-semibold tablet:text-xl mobile:text-xs desktop:text-2xl text-right'>Rwl.roman@yandex.kz</p>
                            <p className='text-white font-semibold tablet:text-xl mobile:text-xs desktop:text-2xl text-right'>Казахстан, Алматы, улица Исаева, дом 159</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default MainPage;
