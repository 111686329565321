import React, { Component } from 'react';
import Navbar from '../ui/Navbar';
import { Link } from 'react-router-dom';

class SelectParkPage extends Component {
    render() {
        return (
            <div className='w-full h-screen bg-[#252521]'>
                <Navbar/>
                <div className='w-full h-screen justify-center items-center flex flex-col'>
                    <p className='text-white text-3xl font-light'>Выберите таксопарк:</p>
                    <div className='flex flex-row w-9/12 items-center justify-center flex-wrap'>
                        <Link to='/form/Allience' className='bg-gradient-to-r from-amber-200 to-yellow-400 items-center justify-center flex rounded-xl p-12 w-72 hover:opacity-50 m-3'>
                            <p className='text-white text-4xl font-semibold'>Альянс</p>
                        </Link>
                        <Link to='/form/Wanderers' className='bg-gradient-to-r from-amber-200 to-yellow-400 items-center justify-center flex rounded-xl p-12 w-72 hover:opacity-50 m-3'>
                            <p className='text-white text-4xl font-semibold'>Странники</p>
                        </Link>
                        <Link to='/form/Gpark' className='bg-gradient-to-r from-amber-200 to-yellow-400 items-center justify-center flex rounded-xl p-12 w-72 hover:opacity-50 m-3'>
                            <p className='text-white text-4xl font-semibold'>Gpark</p>
                        </Link>
                    </div>
                </div>
            </div>
        )

    }
};

export default SelectParkPage;