import React, { Component, useState } from 'react';
import Navbar from '../ui/Navbar';
import { Link } from 'react-router-dom';
import { IoMdCheckmark } from "react-icons/io";


class FormPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCheckbox: false
        }
    }
    render() {
        return (
            <div className='w-full h-screen bg-[#252521]'>
                <Navbar/>
                <div className='w-full h-screen justify-center items-center flex flex-col space-y-6'>
                    <div className='flex flex-col mobile:space-y-4 tablet:space-y-2 desktop:space-y-6'>
                        <div className='border-b tablet:w-52 desktop:w-96 p-4'>
                            <input className='text-white/50 tablet:text-xl tablet:w-48 desktop:w-56  desktop:text-3xl bg-transparent outline-none' placeholder='Введите имя'/>
                        </div>
                        <div className='border-b tablet:w-52 desktop:w-96 p-4'>
                            <input className='text-white/50 tablet:text-xl tablet:w-48 desktop:w-56  desktop:text-3xl bg-transparent outline-none' placeholder='Введите номер'/>
                        </div>
                        <div className='border-b tablet:w-52 desktop:w-96 p-4'>
                            <input className='text-white/50 tablet:text-xl desktop:text-3xl tablet:w-48 desktop:w-80 bg-transparent outline-none' placeholder='Введите сумму'/>
                        </div>
                        <div className='flex flex-row items-center space-x-4'>
                            <button onClick={() => this.setState({ isCheckbox: !this.state.isCheckbox })} className={ this.state.isCheckbox ? 'bg-[#FDC200] h-6 w-6 rounded-md hover:opacity-50 flex justify-center items-center' : 'border-2 border-[#FDC200] h-6 w-6 rounded-md hover:opacity-50' }>
                                { this.state.isCheckbox && <IoMdCheckmark size={16} color='#080808'/> }
                            </button>
                            <p className='text-white text-lg font-light w-96'>Я соглашаюсь с обработкой моих персональных данных</p>
                        </div>
                        <button className='border-2 rounded-2xl p-2 justify-center items-center flex border-[#FDC200] group hover:bg-[#FDC200]'>
                            <p className='text-[#FDC200] tablet:text-xl desktop:text-3xl group-hover:text-white'>Пополнить</p>
                        </button>
                    </div>  
                </div>
            </div>
        )

    }
};

export default FormPage;