import React, { Component } from 'react';
import Navbar from '../ui/Navbar';
import { Link } from 'react-router-dom';

class Greetings extends Component {
    render() {
        return (
            <>
                <img src={require('../../img/background.png')} className='absolute -z-10 inset-0 w-full h-full object-cover' alt="Background" />
                <div className='w-full h-screen justify-center items-center flex flex-col mobile:space-y-2 tablet:space-y-4 desktop:space-y-6'>
                    <p className='mobile:text-2xl tablet:text-4xl desktop:text-5xl font-semibold text-white'><span className='text-[#FDC200]'>Яндекс</span> Пополнение</p>
                    <p className='text-white mobile:text-xl tablet:text-2xl tablet:w-[400px] desktop:text-3xl text-center mobile:w-[300px] desktop:w-[600px] font-light'>Мгновенное пополнение баланса для водителей Яндекс. Всего пара кликов, и вы в седле! 🚗</p>
                    <div className='flex flex-row space-x-6'>
                        <a href='#faq' className='border-2 border-white rounded-xl mobile:p-2 desktop:p-3 px-10 hover:opacity-50'>
                            <p className='text-white mobile:text-xl desktop:text-3xl font-light'>Подробнее</p>
                        </a>
                        <Link to='/select-park' className='border-2 border-[#FDC200] rounded-xl mobile:p-2 desktop:p-3 px-10 hover:opacity-50'>
                            <p className='text-[#FDC200] mobile:text-xl desktop:text-3xl font-light'>Пополнить</p>
                        </Link>
                    </div>
                </div>
            </>
        )
    }
};

export default Greetings;
